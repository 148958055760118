import { CommonModule } from '@angular/common';
import { Component, HostListener, NgZone } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonApp, IonSelectOption, IonSelect, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader, IonNote, IonMenuToggle, IonItem, IonIcon, IonLabel, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { UtilService } from './services/util/util.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { register } from 'swiper/element/bundle';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from 'src/environments/environment';
import { NotificationService } from './services/notification/notification.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    CommonModule,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonContent,
    IonList,
    IonListHeader,
    IonNote,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    TranslateModule,
    IonSelectOption,
    IonSelect,
  ],
})
export class AppComponent {

  public logado: boolean = false;
  public isDesktop: boolean = true;
  public userData: any;
  public urlRoot: string = '/';

  constructor(
    private platform: Platform,
    private translateService: TranslateService,
    private util: UtilService,
    public authService: AuthenticationService,
    private route: Router,
    private zone: NgZone,
    private notificationService: NotificationService
  ) {
    this.initializeApp();
    this.initializeTheme();

    // Detecta se é desktop para alterar comportamentos
    this.util.isDesktopView().subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // window.location.reload(); // Habilite se necessário
      }
      this.isDesktop = isDesktop;
    });

    // Verifica idioma padrão ao iniciar o app
    this.detectDefaultLanguage();

    // Reseta idioma com base no usuário logado
    this.authService.userData.subscribe(user => {
      if (user) {
        this.logado = true;
        this.userData = user;
        this.urlRoot = '/home';

        const idioma = user.language || this.getBrowserOrDeviceLanguage();
        user.language = idioma;
        this.applyLanguage(idioma);

        if (this.platform.is('hybrid')) {
          this.notificationService.requestNotificationPermission(user);
        }
      } else {
        // Sem usuário logado, usa idioma do navegador/dispositivo
        this.detectDefaultLanguage();
      }
    });
  }

  /**
   * Detecta o idioma padrão do dispositivo/navegador.
   */
  private detectDefaultLanguage() {
    const idioma = this.getBrowserOrDeviceLanguage();
    this.applyLanguage(idioma);
  }

  /**
   * Retorna o idioma do navegador ou dispositivo.
   */
  private getBrowserOrDeviceLanguage(): any {
    if (this.platform.is('hybrid')) {
      // Para apps híbridos, usa Globalization plugin
      const defaultLang = 'pt'; // Idioma fallback
      (window as any).cordova.plugins.globalization.getPreferredLanguage(
        (result: any) => this.applyLanguage(result.value.split('-')[0] || defaultLang),
        () => this.applyLanguage(defaultLang)
      );
    } else {
      // Para web, usa a API do navegador
      return navigator.language.split('-')[0] || 'pt';
    }
  }

  /**
   * Aplica o idioma ao app.
   */
  private applyLanguage(language: string) {
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
  }


  mudarIdioma(event: any) {
    const idiomaSelecionado = event.detail.value;
    this.translateService.use(idiomaSelecionado);
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Exemplo de URL: https://meu.fan/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split("meu.fan").pop();
        this.util.consolelog('deeplink', slug);
        if (slug) {
          this.route.navigateByUrl(slug);
        }
        // Caso não haja correspondência, não faça nada e
        // deixe a lógica de roteamento padrão assumir o controle
      });
    });

    this.platform.ready().then(() => {

      if (this.platform.is('hybrid')) {
        OneSignal.initialize(environment.onesignal_id);

        OneSignal.Notifications.addEventListener('click', async (e) => {
          let clickData = await e.notification;
          console.log("Notification Clicked : " + clickData);
        });
      }
      this.util.onResize(this.platform.width());
    });
  }

  async initializeTheme() {
    await this.util.loadTheme();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: { target: { innerWidth: number; }; }) {
    this.util.onResize(event.target.innerWidth);
  }


  onWillDismiss(event: any) {

  }
  cancel() {

  }
  confirm() {

  }

  sair() {
    this.authService.logout();
    this.logado = false;
    this.route.navigate(['/']);
  }


}
