import { LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { registerLocaleData } from '@angular/common';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';
import { MomentModule } from 'ngx-moment';

import localePt from '@angular/common/locales/pt';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './app/services/authentication/auth.guard';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AuthModule } from '@auth0/auth0-angular';
import { domain, clientId, callbackUri } from './../src/app/services/authentication/auth.config';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localePt);
defineCustomElements(window);


if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "pt" },
    provideIonicAngular({
      rippleEffect: false,
      mode: 'md'
    }),
    AuthGuard,
    importProvidersFrom(
      AuthModule.forRoot({
        domain: domain,
        clientId: clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: false,
        //cacheLocation: 'localstorage',
        authorizationParams: {
          callbackUri,
        },
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      MomentModule,
      HttpClientModule,
      IonicStorageModule.forRoot({
        name: '__meufan',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      })
    ),
    provideRouter(routes),
  ],
  // provideServiceWorker('ngsw-worker.js', {
  //   enabled: !isDevMode(),
  //   registrationStrategy: 'registerWhenStable:30000'
  // })
});
