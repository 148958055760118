import { CommonModule } from '@angular/common';
import { Component, HostListener, NgZone } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonApp, IonSelectOption, IonSelect, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonSplitPane, IonMenu, IonContent, IonList, IonListHeader, IonNote, IonMenuToggle, IonItem, IonIcon, IonLabel, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { UtilService } from './services/util/util.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { register } from 'swiper/element/bundle';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from 'src/environments/environment';
import { NotificationService } from './services/notification/notification.service';
import { AuthService } from '@auth0/auth0-angular';
import { Browser } from '@capacitor/browser';
import { mergeMap, switchMap, tap, throwError } from 'rxjs';
import { callbackUri } from './../app/services/authentication/auth.config';
import { Device } from '@capacitor/device';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    CommonModule,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonContent,
    IonList,
    IonListHeader,
    IonNote,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    TranslateModule,
    IonSelectOption,
    IonSelect,
  ],
})
export class AppComponent {

  public logado: boolean = false;
  public isDesktop: boolean = true;
  public userData: any;
  public urlRoot: string = '/';

  constructor(
    private platform: Platform,
    private translateService: TranslateService,
    private util: UtilService,
    public authService: AuthenticationService,
    private route: Router,
    private zone: NgZone,
    private notificationService: NotificationService,
    public auth: AuthService
  ) {
    this.initializeApp();
    this.initializeTheme();

    // Detecta se é desktop para alterar comportamentos
    this.util.isDesktopView().subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // window.location.reload(); // Habilite se necessário
      }
      this.isDesktop = isDesktop;
    });

    // Verifica idioma padrão ao iniciar o app
    this.detectDefaultLanguage();

    // Reseta idioma com base no usuário logado
    this.authService.userData.subscribe(user => {
      if (user) {
        this.logado = true;
        this.userData = user;
        this.urlRoot = '/home';

        const idioma = user.language || this.getBrowserOrDeviceLanguage();
        user.language = idioma;
        this.applyLanguage(idioma);

        if (this.platform.is('hybrid')) {
          this.notificationService.requestNotificationPermission(user);
        }
      } else {
        // Sem usuário logado, usa idioma do navegador/dispositivo
        this.detectDefaultLanguage();
      }
    });
  }

  /**
   * Detecta o idioma padrão do dispositivo/navegador.
   */
  private detectDefaultLanguage() {
    this.getBrowserOrDeviceLanguage().then(idioma => {
      console.log('Idioma padrão:', idioma);
      this.applyLanguage(idioma);
      return idioma;
    });
  }

  /**
   * Retorna o idioma do navegador ou dispositivo.
   */
  private async getBrowserOrDeviceLanguage(): Promise<string> {
    if (this.platform.is('hybrid')) {
      const info = (await Device.getLanguageCode()).value;
      console.log('Info:', info);
      return info.split('-')[0] || 'pt'; // pega o idioma padrão se não encontrar
    } else {
      return navigator.language.split('-')[0] || 'pt';
    }
  }

  /**
   * Aplica o idioma ao app.
   */
  private applyLanguage(language: string) {
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
  }


  mudarIdioma(event: any) {
    const idiomaSelecionado = event.detail.value;
    this.translateService.use(idiomaSelecionado);
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        this.util.consolelog('URL aberta:', event.url);

        // Verifica se é um deeplink do Auth0
        if (event.url?.startsWith(callbackUri)) {
          this.util.consolelog('Callback do Auth0 detectado:', event.url);

          if (event.url.includes('state=') && (event.url.includes('error=') || event.url.includes('code='))) {
            this.auth
              .handleRedirectCallback(event.url)
              .pipe(
                mergeMap(() => this.auth.user$),
                tap((user) => this.util.consolelog('Dados do usuário Auth0:', user)),
                mergeMap((user: any) => {
                  if (user) {
                    const provider = user.sub.startsWith('google') ? 'google' :
                      user.sub.startsWith('apple') ? 'apple' : 'unknown';
                    this.util.consolelog('Provedor detectado:', provider);
                    return this.authService.socialLoginWithAuth0(user, provider);
                  } else {
                    return throwError(() => new Error('Usuário não autenticado.'));
                  }
                }),
                mergeMap(() => Browser.close())
              )
              .subscribe({
                next: () => {
                  this.util.consolelog('Login social bem-sucedido');
                  this.route.navigateByUrl('/home');
                },
                error: (err) => {
                  console.error('Erro no login social:', err);
                  this.route.navigateByUrl('/intro');
                }
              });
          } else {
            Browser.close();
          }
        } else {
          // Tratamento de outros deeplinks
          const slug = event.url.split("meu.fan").pop();
          this.util.consolelog('deeplink', slug);
          if (slug) {
            this.route.navigateByUrl(slug);
          }
        }
      });
    });

    this.platform.ready().then(() => {

      if (this.platform.is('hybrid')) {
        OneSignal.initialize(environment.onesignal_id);

        OneSignal.Notifications.addEventListener('click', async (e) => {
          let clickData = await e.notification;
          this.util.consolelog("Notification Clicked : " + clickData);
        });
      }
      this.util.onResize(this.platform.width());
    });
  }

  async initializeTheme() {
    await this.util.loadTheme();
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: { target: { innerWidth: number; }; }) {
    this.util.onResize(event.target.innerWidth);
  }

  onWillDismiss(event: any) {

  }
  cancel() {

  }
  confirm() {

  }

  sair() {
    this.authService.logout();
    this.logado = false;
    this.route.navigate(['/']);
  }

  ngOnInit(): void {
    this.util.consolelog('ngOnInit app...');
    this.initializeTheme();

    if (this.platform.is('hybrid')) {
      this.util.consolelog('Ionic Hybrid App/On device');
      this.initializeApp(); // Usa o listener para apps móveis
    } else {
      this.processAuthLogin();
    }
  }

  async processAuthLogin() {
    const loading = await this.util.loading('Login...');

    this.util.consolelog('Ionic PWA/Web Detected');
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (code && state) {

      loading.present();
      this.auth.isAuthenticated$.pipe(
        switchMap((isAuthenticated) => {
          this.util.consolelog('Usuário autenticado?', isAuthenticated);
          if (isAuthenticated) {
            return this.auth.user$;
          } else {
            return [];
          }
        }), switchMap((user: any) => {
          if (user) {
            const provider = user.sub.startsWith('google')
              ? 'google'
              : user.sub.startsWith('apple')
                ? 'apple'
                : 'unknown';

            this.util.consolelog('Provedor detectado:', provider);

            // Envia os dados do usuário para a API
            return this.authService.socialLoginWithAuth0(user, provider);
          } else {
            this.util.consolelog('Usuário não logado.');
            return [];
          }
        })
      ).subscribe({
        next: () => {
          this.util.consolelog('Login social bem-sucedido e dados enviados à API');
          loading.dismiss();
          this.route.navigate(['/'], { replaceUrl: true });
        },
        error: (err) => {
          console.error('Erro ao enviar os dados para a API:', err);
          this.route.navigate(['/intro']);
        },
      });
    }
  }
}
