import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from 'src/environments/environment';
import { UtilService } from '../util/util.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public isPermissionGranted: boolean = false;

  constructor(private platform: Platform, private util: UtilService, private translate: TranslateService) { }

  /**
   * Solicita permissão para notificações push.
   */
  async requestNotificationPermission(user: any): Promise<void> {
    if (this.platform.is('hybrid')) {
      try {
        const granted = await OneSignal.Notifications.requestPermission(true);
        this.isPermissionGranted = granted;
        this.util.consolelog("Notification permission granted: ", granted);

        if (!granted) {
          this.util.consolelog("Permission denied. Notifications will not work.");
        } else {
          this.util.consolelog('User for push', user);
          if (user) {
            this.registerUser(user);
          }
        }
      } catch (error) {
        this.util.consolelog("Error requesting notification permission: ", error);
      }
    } else {
      this.util.consolelog("Notification permission requested on non-hybrid platform.");
    }
  }

  /**
   * Registra o usuário no OneSignal após permissão concedida.
   * @param userId ID do usuário no sistema
   * @param email Email do usuário
   * @param language Idioma do usuário
   */
  registerUser(user: any): void {
    if (this.platform.is('hybrid')) {
      var userId = user.id;
      var email = user.email;
      var language = user.language;
      if (this.isPermissionGranted) {
        OneSignal.login(userId.toString());
        OneSignal.User.setLanguage(language);
        OneSignal.User.addEmail(email);
        this.util.consolelog(`User registered in OneSignal with ID: ${userId}, Email: ${email}, Language: ${language}`);
      } else {
        this.util.consolelog("User not registered in OneSignal. Permission not granted.");
      }
    } else {
      this.util.consolelog("Attempted to register user on non-hybrid platform.");
    }
  }

  /**
   * Verifica se a permissão foi negada no iOS/Android e retorna as instruções.
   * @returns Instruções específicas para habilitar notificações.
   */
  getNotificationSettingsInstructions(): string {
    if (this.platform.is('ios')) {
      return this.translate.instant('CONFIGURATIONS.IOS_NOTIFICATION');
    } else if (this.platform.is('android')) {
      return this.translate.instant('CONFIGURATIONS.ANDROID_NOTIFICATION');
    } else {
      return "";
    }
  }
}
